import React from "react"
import PropTypes from "prop-types"

import { ColorMenuContextProvider } from "../contexts/MenuColorContexts"
import Transition from "../components/transition"
import MainHeader from "./MainHeader"
import MainFooter from "./MainFooter"
import "./layout.css"

const Layout = ({ children, location }) => {
  return (
    <ColorMenuContextProvider>
      <MainHeader />
      <div className="layout-body">
        <main>
          <Transition location={location}>{children}</Transition>
        </main>
      </div>
      <MainFooter />
    </ColorMenuContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
