import React from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import values from "../../utils/values"

const StyledLangList = styled.ul`
  height: 100%;
  width: 50px;
  margin-right: 40px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  color: ${props =>
    props.darkTheme
      ? values.COLORS.darkTextColor
      : values.COLORS.lightTextColor};
  .langListItem {
    margin-bottom: 0;
    color: inherit;
    position: relative;
    min-width: fit-content;
    word-wrap: normal;

    .underline:before {
      content: "";
      display: block;
      background: currentColor;
      position: absolute;
      width: 0;
      height: 1px;
      bottom: -5px;
      right: 0;
    }

    .underline:before {
      width: 100%;
    }
  }
`

const ChangeLangButton = ({ isDarkTheme }) => {
  const { language, languages, originalPath } = useI18next()
  return (
    <>
      <StyledLangList darkTheme={isDarkTheme}>
        {languages.map(lng => (
          <li key={lng} className="langListItem">
            <Link
              className={language === lng ? "underline" : ""}
              to={originalPath}
              language={lng}
            >
              {lng}
            </Link>
          </li>
        ))}
      </StyledLangList>
    </>
  )
}

export default ChangeLangButton
