import styled from "styled-components"
import values from "../utils/values"
import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const Footer = styled.footer`
  width: 100%;
  font-size: 0.8rem;
  padding: 1rem 5px;
  background-color: ${values.COLORS.secondLightBackgroundColor};

  .footer-date {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
  }

  .footer-info {
    width: 100%;
    display: flex;
    justify-content: center;
    .phone span {
      display: block;
    }
    
    .dasauge-link{
    display:block;
margin-left: 2rem;
    }

    .contacts-wrap {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;

      .underline {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: ${values.DEVICESIZE.laptop}) {
    font-size: 0.9rem;

    .footer-info {
      .contacts-wrap {
        margin-left: 2rem;

        .underline {
          text-decoration: underline;
        }
      }
    }
  }
`

export default function MainFooter() {
  const {
    title,
    email,
    address,
    phone1,
    telegram,
    registrationID,
    taxID,
  } = useSiteMetadata()

  return (
    <Footer>
      <span className="footer-date">
        © {new Date().getFullYear() + " " + title || `WEB-BOXES.COM`}
      </span>
      <div className="footer-info">
        <div>
          <p>Company Registration ID {registrationID}</p>
          <p>TAX ID & VAT payer ID {taxID}</p>
        </div>
        <div className="contacts-wrap">
          <p>{address}</p>
          <span>
            {phone1} (
            <a
              href={telegram}
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              telegram
            </a>
            )
          </span>
          <span>
            email:{" "}
            <a
              className="underline"
              href={`mailto:${email}?subject=Questions/request to web-boxes.com`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </a>
          </span>
        </div>
        <div>
          <p>
            <a className="dasauge-link" rel="me" href="https://dasauge.com/-web-boxes/" title="WebBoxes Company at dasauge" target="_blank"><img src="https://cdn.dasauge.net/prolinks/dasauge_en_trans.png" alt="WebBoxes Company" width="85" height="23" style={{"border":0, height: "auto"}} /></a>
          </p>
        </div>
      </div>
    </Footer>
  )
}
