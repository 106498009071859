import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          phone1
          telegram
          phone2
          phone3
          email
          address
          instagram
          twitter
          facebook
          linkedin
          medium
          steemit
          douUA
          registrationID
          taxID
        }
      }
    }
  `)
  return site.siteMetadata
}
