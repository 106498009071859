import React, { Component } from "react"
const { Provider, Consumer } = React.createContext()

class ColorMenuContextProvider extends Component {
  state = {
    colorMenu: "dark",
    isOnDarkArea: false,
  }

  setMenuColor = menuColor => {
    this.setState({ colorMenu: menuColor })
  }

  setIsOnDarkArea = IsIntersecting => {
    this.setState({ isOnDarkArea: IsIntersecting })
  }

  render() {
    const value = {
      colorMenuState: this.state.colorMenu,
      isOnDarkArea: this.state.isOnDarkArea,
      setIsOnDarkArea: this.setIsOnDarkArea,
      setMenuColor: this.setMenuColor,
    }
    return <Provider value={value}>{this.props.children}</Provider>
  }
}

export { ColorMenuContextProvider, Consumer as ColorMenuContextConsumer }
