import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { HamburgerSlider } from "react-animated-burgers"
import withMenuColor from "../hocs/withMenuColor"
import Modal from "../components/SingleComponents/Modal"
import Nav from "../components/SingleComponents/Nav"
import NavFooter from "../components/SingleComponents/NavFooter"
import ChangeLangButton from "../components/SingleComponents/ChangeLangButton"

import Logo from "../images/logo/webboxes_logo_optimized.svg"
import values from "../utils/values"

const StyledHeaderMenu = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100vw;
  background-color: ${props =>
    props.darkTheme ? values.COLORS.darkBackgroundColor : "transparent"};
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.4rem;

  a:active {
    transform: scale(0.9);
    transition: transform 0.8s;
  }

  .navigation-bar {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: ${values.DEVICESIZE.laptop}) {
    height: 5rem;
    padding: 3rem;
  }
`

class MainHeader extends Component {
  state = {
    showModalMenu: false,
  }

  onShowMenu = ({ target }) => {
    const { showModalMenu } = this.state
    if (target.matches("#logo") && !showModalMenu) {
      return
    }

    if (!this.props.colorMenuContext.isOnDarkArea) {
      const colorMenu = showModalMenu === true ? "dark" : "light"
      this.props.colorMenuContext.setMenuColor(colorMenu)
    }

    this.setState(prevState => {
      return {
        showModalMenu: !prevState.showModalMenu,
      }
    })
  }

  render() {
    const { showModalMenu } = this.state
    const { colorMenuState } = this.props.colorMenuContext
    return (
      <>
        <StyledHeaderMenu darkTheme={showModalMenu}>
          <Link to="/" onClick={this.onShowMenu}>
            <img id="logo" src={Logo} alt="Webboxes Logo" />
          </Link>
          <div className="navigation-bar">
            <ChangeLangButton isDarkTheme={colorMenuState === "dark"} />
            <HamburgerSlider
              className="hamburgerSlider"
              buttonWidth={34}
              isActive={showModalMenu}
              toggleButton={this.onShowMenu}
              barColor={
                colorMenuState === "light"
                  ? values.COLORS.lightTextColor
                  : values.COLORS.darkTextColor
              }
            />
          </div>
        </StyledHeaderMenu>
        {showModalMenu && (
          <div onClick={this.onShowMenu}>
            <Modal>
              <Nav />
              <NavFooter />
            </Modal>
          </div>
        )}
      </>
    )
  }
}

export default withMenuColor(MainHeader)
