import React from "react"
import { ColorMenuContextConsumer } from "../contexts/MenuColorContexts"

const withMenuColor = WrappedComponent => {
  return function WithMenuColor(props) {
    return (
      <ColorMenuContextConsumer>
        {context => <WrappedComponent {...props} colorMenuContext={context} />}
      </ColorMenuContextConsumer>
    )
  }
}

export default withMenuColor
