import React from "react"
import styled from "styled-components"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import values from "../../utils/values"

const FooterList = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  text-align: right;
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    li {
      margin-right: 1.2rem;
      font-size: 1rem;
      position: relative;
      min-width: fit-content;
      word-wrap: normal;

      .underline:before {
        content: "";
        display: block;
        background: currentColor;
        position: absolute;
        width: 0;
        height: 1px;
        bottom: -5px;
        right: 0;
        -webkit-transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        -o-transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
      }

      .underline:hover:before {
        width: 100%;
      }
    }
  }

  @media (min-width: ${values.DEVICESIZE.laptop}) {
    right: 1.6rem;
    bottom: 7rem;
    flex-direction: row;
    ul {
      li {
        margin-right: 3rem;
        font-size: 1.3rem;
        position: relative;
      }
    }
  }
`

export default function NavFooter() {
  const {
    email,
    phone1,
    facebook,
    linkedin,
    instagram,
    twitter,
  } = useSiteMetadata()

  return (
    <FooterList>
      <ul>
        <li>
          <a
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${email}?subject=Questions/request to web-boxes.com`}
          >
            {email}
          </a>
        </li>
        <li>
          <a href={`tel:${phone1}`} className="underline">
            {phone1}
          </a>
        </li>
      </ul>
      <ul>
        <li className="hidden-xs">
          <a
            href={linkedin}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </li>
        <li className="hidden-xs">
          <a
            href={facebook}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
        </li>
        <li className="hidden-xs">
          <a
            href={instagram}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </li>
        <li className="hidden-xs">
          <a
            href={twitter}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </li>
      </ul>
    </FooterList>
  )
}
